import React from 'react';
import { Box, Container, Card, CardMedia, CardContent, Typography } from '@mui/material';
import { Link  } from 'react-router-dom';

const GridView = ({ venueData }) => {
    return (
        <Container>
        {
            venueData.map((category, index) => (
                <div key={index}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Typography id={'#gridview-'+index} variant="h5" component="h2">
                            {category.category}
                        </Typography>
                    </Box>
                        {category.venues.map((venue, idx) => (
                            <div key={idx} style={{ padding: '0 20px 20px' }}>
                                <Link to={/*venue.link*/"/org/venue"}>
                                <Card className="slide-card" sx={{ maxWidth: '100%', minHeight: '10vw', margin: '0 auto', borderRadius: 5, backdropFilter: 'blur(8px)' }}>
                                    <CardMedia
                                        component="img"
                                        height="140px"
                                        image={venue.image}
                                        alt={venue.name}
                                        sx={{ borderRadius: '10px 10px 0 0' }} // Round only top corners of the image
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {venue.name}
                                        </Typography>
                                        {/* <Typography variant="body2" color="text.secondary">
                                            {venue.rating} ★
                                        </Typography> */}
                                        <Typography variant="body2" color="text.secondary">
                                            {venue.deliveryFee} • {venue.deliveryTime}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                </Link>
                            </div>
                        ))}
                </div>
            ))
        }
        </Container>
    )
}

export default GridView;