import React, { useState } from "react";
import {
  FormControl,
  InputAdornment,
  TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";



const TypeSearch = ({setSearchTerm, placeHolder}) => {

  const [showClearIcon, setShowClearIcon] = useState("none");

  const handleChange = (event) => {
    setSearchTerm(event.target.value)
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
  };

  const handleClick = () => {
    // TODO: Clear the search input
    console.log("clicked the clear icon...");
  };

  return (
      <FormControl>
        <TextField
          size="small"
          defaultValue= {placeHolder || ''}
          variant="outlined"
          sx={{ mr: 2, width: '65vw', maxWidth: '400px', backgroundColor: 'white', borderRadius: 3 }}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ display: showClearIcon }}
                onClick={handleClick}
              >
                <ClearIcon />
              </InputAdornment>
            )
          }}
        />
      </FormControl>
  );
};

export default TypeSearch;
