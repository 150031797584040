import React from 'react';
import { Link  } from 'react-router-dom';
import { Typography, Box, Button, Card, CardMedia, CardContent } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../App.css';
import Slider from "react-slick";

const VenueView = ({venueData, settingsOverride}) => {
    
    /*const venueData2 = [
        {
            "category": "🇪🇪 Popular in Tallinn",
            "venues": [
                {
                    "name": "Indoor Football Court",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400"
                },
            ]
        }
    ]; */

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "block",
                    background: "#00000000",
                    borderRadius: "50%",
                    padding: "10px"
                }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick, currentSlide } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: currentSlide === 0 ? "none" : "block",
                    background: "#00000000",
                    borderRadius: "50%",
                    padding: "10px"
                }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 600,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: false,
                    arrows: false // Hide arrows on screens smaller than 600px
                }
            }
        ],
        swipeToSlide: true,
        pauseOnHover: false, // Disable hover effect
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        ...settingsOverride
    };


    return (
        <>
        {
            venueData.map((category, index) => (
                <div key={index}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h5" component="h2">
                            {category.category}
                        </Typography>
                        <Button variant="text" size="small" color="inherit" sx={{ textTransform: 'none' }}>
                            See All
                        </Button>
                    </Box>
                    <Slider {...settings}>
                        {category.venues.map((venue, idx) => (
                            <div key={idx} style={{ padding: '0 20px' }}>
                                <Link to={/*venue.link*/"/org"}>
                                <Card className="slide-card" sx={{ maxWidth: 345, margin: '0 auto', borderRadius: 10, backdropFilter: 'blur(8px)' }}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={venue.image}
                                        alt={venue.name}
                                        sx={{ borderRadius: '10px 10px 0 0' }} // Round only top corners of the image
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {venue.name}
                                        </Typography>
                                        {/* <Typography variant="body2" color="text.secondary">
                                            {venue.rating} ★
                                        </Typography> */}
                                        <Typography variant="body2" color="text.secondary">
                                            {venue.deliveryFee} • {venue.deliveryTime}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                </Link>
                            </div>
                        ))}
                    </Slider>
                </div>
            ))
        }
        </>
    )
}

export default VenueView;