import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import Venue from './pages/Venue';
import GroupedVenue from './pages/GroupedVenue';
import Book from './pages/Book';
import Org from './pages/Org';
import Search from './pages/Search';

function App() {
  return (
    <>
    <Navbar />
    <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<Search />} />
          <Route path="/org" element={<Org />} />
          <Route path="/org/venue" element={<Venue />} />
          <Route path="/org/groupedvenue" element={<GroupedVenue />} />
          <Route path="/org/venue/book" element={<Book />} />
    </Routes>
    <Footer />
    </>
  );
}

export default App;
