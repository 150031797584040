import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import SearchBar from '../components/SearchBar';
import VenueView from '../components/VenueView';

const steps = [
  {
    title: 'Find the perfect space',
    description: 'Browse the marketplace and customize your search to suit your activity needs.',
    icon: 'search', // Use relevant icon
  },
  {
    title: 'Book it with ease',
    description: 'Once you find the ideal match, reserve the space and pay securely online with payment system.',
    icon: 'book_online', // Use relevant icon
  },
  {
    title: 'Meet, make or create',
    description: 'Create a memorable experience in a memorable space, and enjoy!',
    icon: 'event', // Use relevant icon
  }
];

function Home() {
  return (
    <div>
      <Box
        sx={{
          backgroundImage: 'url(https://sportivebuddy.com/images/image6.jpeg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '50vh',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Container>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant="h3" component="div" color="white">
              From casual games to competitive matches
              </Typography>
            </Grid>
            <Grid item>
              <SearchBar />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h4" component="div">
          Find the best sports spots in your community and book with ease.
          </Typography>
          <Grid container spacing={4} sx={{ mt: 2 }}>
            {steps.map((step, index) => (
              <Grid item key={index} xs={12} sm={4}>
                <Box sx={{ textAlign: 'center', p: 2 }}>
                  <Box sx={{ fontSize: '50px', mb: 2 }}>
                    <span className="material-icons" style={{fontSize: '75px'}}>{step.icon}</span>
                  </Box>
                  <Typography variant="h6" component="div" gutterBottom>
                    {step.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {step.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          {/* <Typography variant="h4" component="div">
            Explore locations
          </Typography> */}
          <VenueView venueData={[
            {
              "category": "🇪🇪 Popular in Tallinn",
              "venues": [
                {
                  "name": "Tallinn Arena",
                  "location": "Kesklinn",
                  "availableTime": "7am - 10pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/tallinn-arena"
                },
                {
                  "name": "Nordic Sports Complex",
                  "location": "Põhja-Tallinn",
                  "availableTime": "6am - 9pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/nordic-sports-complex"
                },
                {
                  "name": "Tallinn Fitness Hub",
                  "location": "Lasnamäe",
                  "availableTime": "24/7",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/tallinn-fitness-hub"
                },
                {
                  "name": "Harju Sports Pavilion",
                  "location": "Harju",
                  "availableTime": "8am - 8pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/harju-sports-pavilion"
                },
                {
                  "name": "Seaside Recreation Center",
                  "location": "Pirita",
                  "availableTime": "6am - 10pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/seaside-recreation-center"
                },
                {
                  "name": "Tallinn Sports Arena",
                  "location": "Tõnismäe",
                  "availableTime": "9am - 11pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/tallinn-sports-arena"
                },
                {
                  "name": "City Athletic Club",
                  "location": "Kalamaja",
                  "availableTime": "8am - 9pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/city-athletic-club"
                },
                {
                  "name": "Metro Sports Park",
                  "location": "Vaksali",
                  "availableTime": "7am - 10pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/metro-sports-park"
                },
                {
                  "name": "Estonian Sports Center",
                  "location": "Läänemere",
                  "availableTime": "7am - 9pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/estonian-sports-center"
                },
                {
                  "name": "Victory Stadium",
                  "location": "Kumpus",
                  "availableTime": "8am - 10pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/victory-stadium"
                }
              ]
            },
            {
              "category": "⚽ Football",
              "venues": [
                {
                  "name": "Tallinn Arena",
                  "rating": 4.7,
                  "deliveryFee": "Free",
                  "deliveryTime": "7am - 10pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/tallinn-arena"
                },
                {
                  "name": "Harju Sports Pavilion",
                  "rating": 4.5,
                  "deliveryFee": "Free",
                  "deliveryTime": "8am - 8pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/harju-sports-pavilion"
                }
              ]
            },
            {
              "category": "🏀 Basketball",
              "venues": [
                {
                  "name": "Nordic Sports Complex",
                  "rating": 4.8,
                  "deliveryFee": "Free",
                  "deliveryTime": "6am - 9pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/nordic-sports-complex"
                },
                {
                  "name": "City Athletic Club",
                  "rating": 4.6,
                  "deliveryFee": "Free",
                  "deliveryTime": "8am - 9pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/city-athletic-club"
                }
              ]
            },
            {
              "category": "🏒 Ice Hockey",
              "venues": [
                {
                  "name": "Tallinn Sports Arena",
                  "rating": 4.9,
                  "deliveryFee": "Free",
                  "deliveryTime": "9am - 11pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/tallinn-sports-arena"
                },
                {
                  "name": "Victory Stadium",
                  "rating": 4.7,
                  "deliveryFee": "Free",
                  "deliveryTime": "8am - 10pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/victory-stadium"
                }
              ]
            },
            {
              "category": "🏊 Swimming",
              "venues": [
                {
                  "name": "Seaside Recreation Center",
                  "rating": 4.6,
                  "deliveryFee": "Free",
                  "deliveryTime": "6am - 10pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/seaside-recreation-center"
                },
                {
                  "name": "Tallinn Fitness Hub",
                  "rating": 4.8,
                  "deliveryFee": "Free",
                  "deliveryTime": "24/7",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/tallinn-fitness-hub"
                }
              ]
            },
            {
              "category": "🏸 Badminton",
              "venues": [
                {
                  "name": "Metro Sports Park",
                  "rating": 4.4,
                  "deliveryFee": "Free",
                  "deliveryTime": "7am - 10pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/metro-sports-park"
                },
                {
                  "name": "Estonian Sports Center",
                  "rating": 4.5,
                  "deliveryFee": "Free",
                  "deliveryTime": "7am - 9pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/estonian-sports-center"
                }
              ]
            }
          ]
          } />
        </Box>
      </Container>
    </div>
  );
}

export default Home;
