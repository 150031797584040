import React from 'react';
import { Container, Grid, Typography, List, ListItem, ListItemText, Divider, Box, Avatar, CardMedia } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';
import GridView from '../components/GridView';

const menuItems = [
    {
      "category": "⚽ Football",
      "venues": [
        {
          "name": "Tallinn Arena",
          "rating": 4.7,
          "deliveryFee": "Free",
          "deliveryTime": "7am - 10pm",
          "image": "https://placehold.co/600x400",
          "link": "/ee/Tallinn/tallinn-arena"
        },
        {
          "name": "Harju Sports Pavilion",
          "rating": 4.5,
          "deliveryFee": "Free",
          "deliveryTime": "8am - 8pm",
          "image": "https://placehold.co/600x400",
          "link": "/ee/Tallinn/harju-sports-pavilion"
        }
      ]
    },
    {
      "category": "🏀 Basketball",
      "venues": [
        {
          "name": "Nordic Sports Complex",
          "rating": 4.8,
          "deliveryFee": "Free",
          "deliveryTime": "6am - 9pm",
          "image": "https://placehold.co/600x400",
          "link": "/ee/Tallinn/nordic-sports-complex"
        },
        {
          "name": "City Athletic Club",
          "rating": 4.6,
          "deliveryFee": "Free",
          "deliveryTime": "8am - 9pm",
          "image": "https://placehold.co/600x400",
          "link": "/ee/Tallinn/city-athletic-club"
        }
      ]
    },
    {
      "category": "🏒 Ice Hockey",
      "venues": [
        {
          "name": "Tallinn Sports Arena",
          "rating": 4.9,
          "deliveryFee": "Free",
          "deliveryTime": "9am - 11pm",
          "image": "https://placehold.co/600x400",
          "link": "/ee/Tallinn/tallinn-sports-arena"
        },
        {
          "name": "Victory Stadium",
          "rating": 4.7,
          "deliveryFee": "Free",
          "deliveryTime": "8am - 10pm",
          "image": "https://placehold.co/600x400",
          "link": "/ee/Tallinn/victory-stadium"
        }
      ]
    },
    {
      "category": "🏊 Swimming",
      "venues": [
        {
          "name": "Seaside Recreation Center",
          "rating": 4.6,
          "deliveryFee": "Free",
          "deliveryTime": "6am - 10pm",
          "image": "https://placehold.co/600x400",
          "link": "/ee/Tallinn/seaside-recreation-center"
        },
        {
          "name": "Tallinn Fitness Hub",
          "rating": 4.8,
          "deliveryFee": "Free",
          "deliveryTime": "24/7",
          "image": "https://placehold.co/600x400",
          "link": "/ee/Tallinn/tallinn-fitness-hub"
        }
      ]
    },
    {
      "category": "🏸 Badminton",
      "venues": [
        {
          "name": "Metro Sports Park",
          "rating": 4.4,
          "deliveryFee": "Free",
          "deliveryTime": "7am - 10pm",
          "image": "https://placehold.co/600x400",
          "link": "/ee/Tallinn/metro-sports-park"
        },
        {
          "name": "Estonian Sports Center",
          "rating": 4.5,
          "deliveryFee": "Free",
          "deliveryTime": "7am - 9pm",
          "image": "https://placehold.co/600x400",
          "link": "/ee/Tallinn/estonian-sports-center"
        }
      ]
    }
  ]
  


const Org = () => {
  return (
    <Container >
      <Grid container spacing={3}>
      <Grid
          item
          sx={{
            display: { xs: 'none', sm: 'none', lg: 'block', md: 'none' },
            position: 'sticky',
            top: '40px',
            alignSelf: 'flex-start'
          }}
        >
          <List>
            {menuItems.map((text, index) => (
              <ListItem button key={index}>
                <ListItemText primary={text.category} onClick={() =>document.getElementById( '#gridview-'+index ).scrollIntoView( { behavior: 'smooth', block: 'start' } )}/>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} lg={7}>
            <CardMedia
                component="img"
                height="300vw"
                image="https://sportivebuddy.com/images/image6.jpeg"
                style={{borderRadius: 5 }}
                alt="Placeholder Image"
            />
            <Typography variant="h5" gutterBottom>
              Org Name
            </Typography>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <StarIcon />
                4.6 (500+)
              </Grid>
              <Grid item>
                <SearchIcon />
                Contact
              </Grid>
            </Grid>
            <Divider style={{ margin: '16px 0' }} />
            <Typography variant="h6" gutterBottom>
              Quick Info Box
            </Typography>
            <Grid item xs={4}>
            <Box display="flex" alignItems="center">
              <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                <PersonIcon />
              </Avatar>
              <Box>
                <Typography variant="subtitle1">Open</Typography>
                <Typography variant="h6">8am-10pm</Typography>
              </Box>
            </Box>
            </Grid>
            <Divider style={{ margin: '16px 0' }} />
            <GridView venueData={menuItems}/>

        </Grid>
      </Grid>
    </Container>
  );
};

export default Org;
