import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box sx={{ textAlign: 'center', p: 3, mt: 4, borderTop: '1px solid #ccc' }}>
      <Typography variant="body1">
        © 2024 SportiveBuddy. All Rights Reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
