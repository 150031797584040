import React from 'react';
import { Container, Grid, Typography, List, ListItem, ListItemText, Box } from '@mui/material';
import SearchBar from '../components/SearchBar';
import GridView from '../components/GridView';
import { useSearchParams } from 'react-router-dom';

const menuItems = [
    {
        "category": "⚽ Football",
        "venues": [
            {
                "name": "Tallinn Arena",
                "rating": 4.7,
                "deliveryFee": "Free",
                "deliveryTime": "7am - 10pm",
                "image": "https://placehold.co/600x400",
                "link": "/ee/Tallinn/tallinn-arena"
            },
            {
                "name": "Harju Sports Pavilion",
                "rating": 4.5,
                "deliveryFee": "Free",
                "deliveryTime": "8am - 8pm",
                "image": "https://placehold.co/600x400",
                "link": "/ee/Tallinn/harju-sports-pavilion"
            }
        ]
    },
    {
        "category": "🏀 Basketball",
        "venues": [
            {
                "name": "Nordic Sports Complex",
                "rating": 4.8,
                "deliveryFee": "Free",
                "deliveryTime": "6am - 9pm",
                "image": "https://placehold.co/600x400",
                "link": "/ee/Tallinn/nordic-sports-complex"
            },
            {
                "name": "City Athletic Club",
                "rating": 4.6,
                "deliveryFee": "Free",
                "deliveryTime": "8am - 9pm",
                "image": "https://placehold.co/600x400",
                "link": "/ee/Tallinn/city-athletic-club"
            }
        ]
    },
    {
        "category": "🏒 Ice Hockey",
        "venues": [
            {
                "name": "Tallinn Sports Arena",
                "rating": 4.9,
                "deliveryFee": "Free",
                "deliveryTime": "9am - 11pm",
                "image": "https://placehold.co/600x400",
                "link": "/ee/Tallinn/tallinn-sports-arena"
            },
            {
                "name": "Victory Stadium",
                "rating": 4.7,
                "deliveryFee": "Free",
                "deliveryTime": "8am - 10pm",
                "image": "https://placehold.co/600x400",
                "link": "/ee/Tallinn/victory-stadium"
            }
        ]
    },
    {
        "category": "🏊 Swimming",
        "venues": [
            {
                "name": "Seaside Recreation Center",
                "rating": 4.6,
                "deliveryFee": "Free",
                "deliveryTime": "6am - 10pm",
                "image": "https://placehold.co/600x400",
                "link": "/ee/Tallinn/seaside-recreation-center"
            },
            {
                "name": "Tallinn Fitness Hub",
                "rating": 4.8,
                "deliveryFee": "Free",
                "deliveryTime": "24/7",
                "image": "https://placehold.co/600x400",
                "link": "/ee/Tallinn/tallinn-fitness-hub"
            }
        ]
    },
    {
        "category": "🏸 Badminton",
        "venues": [
            {
                "name": "Metro Sports Park",
                "rating": 4.4,
                "deliveryFee": "Free",
                "deliveryTime": "7am - 10pm",
                "image": "https://placehold.co/600x400",
                "link": "/ee/Tallinn/metro-sports-park"
            },
            {
                "name": "Estonian Sports Center",
                "rating": 4.5,
                "deliveryFee": "Free",
                "deliveryTime": "7am - 9pm",
                "image": "https://placehold.co/600x400",
                "link": "/ee/Tallinn/estonian-sports-center"
            }
        ]
    }
]

function filterByValue(array, string) {
    if (!string) {
        return array
    }
    return array.filter(o =>
        Object.keys(o).some(k => o[k].toString().toLowerCase().includes(string.toLowerCase())));
}



const Search = () => {
    const [searchParams] = useSearchParams();
    const queryParamValue = searchParams.get('q');

    return (
        <Container >
            <Grid container spacing={12}>
                <Grid
                    item
                    sx={{
                        display: { xs: 'none', sm: 'none', lg: 'block', md: 'none' },
                        position: 'sticky',
                        top: '40px',
                        alignSelf: 'flex-start'
                    }}
                >
                    <List>
                        {filterByValue(menuItems, queryParamValue).map((text, index) => (
                            <ListItem button key={index}>
                                <ListItemText primary={text.category} onClick={() => document.getElementById('#gridview-' + index).scrollIntoView({ behavior: 'smooth', block: 'start' })} />
                            </ListItem>
                        ))}
                    </List>
                </Grid>

                <Grid item xs={12} lg={7}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        margin={10}
                    >
                        <span><Typography variant="h5" noWrap component="div">
          Search
          </Typography></span>
                        <SearchBar placeHolder={queryParamValue}/>
                    </Box>
                    <GridView venueData={filterByValue(menuItems, queryParamValue)} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Search;
