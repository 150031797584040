import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Drawer, IconButton, List, ListItem, ListItemText, Menu, MenuItem, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import FlagIcon from '@mui/icons-material/Flag'; // Using a placeholder for flags; you can replace with actual flag icons.
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleLanguageButtonClick = (event) => {
    event.stopPropagation(); // Prevent the drawer from closing
    handleMenuClick(event);
  };

  const handleSearch = (event) => {
    navigate('/search');
  };

  
  const drawerContent = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={handleSearch}>
          <ListItemText primary="Search" />
        </ListItem>
        <ListItem button onClick={handleLanguageButtonClick}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary="Language" />
        </ListItem>
        <ListItem button onClick={toggleDrawer(false)}>
          <ListItemText primary="List your space" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
        
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to='/'>
            <img src="https://sportivebuddy.com/images/logo-sportive.svg" alt="Logo" style={{ height: 40, marginTop:10 }} />
            </Link>
          </Typography>
          
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: 'block', md: 'none' }, ml: 'auto' }}
          >
            <MenuIcon />
          </IconButton>
          <Button
            color="inherit"
            startIcon={<LanguageIcon />}
            onClick={handleMenuClick}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
          </Button>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>
              <ListItemIcon>
                <FlagIcon /> {/* Placeholder for actual flag icon */}
              </ListItemIcon>
              English
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <ListItemIcon>
                <FlagIcon /> {/* Placeholder for actual flag icon */}
              </ListItemIcon>
              Estonian
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <ListItemIcon>
                <FlagIcon /> {/* Placeholder for actual flag icon */}
              </ListItemIcon>
              Russian
            </MenuItem>
          </Menu>
          <Button color="inherit" variant="outlined" sx={{ display: { xs: 'none', md: 'block' } }}>
            List your space
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {drawerContent}
      </Drawer>
    </>
  );
}

export default Navbar;
