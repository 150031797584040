import React, {useState} from 'react';
import { Box, Modal, Container, Grid, Typography, Button, Avatar,  TextField } from '@mui/material';
import { styled } from '@mui/system';
import ScheduleSelector from 'react-schedule-selector'
import GroupIcon from '@mui/icons-material/Group';
import { Link } from 'react-router-dom'
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

const HeaderImage = styled('img')({
    width: '100%',
    height: 'auto',
  });
  
const SideImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const BookingBox = styled(Box)({
  padding: 20,
  border: '1px solid #ddd',
  borderRadius: 4,
});

const FeatureBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 0',
});

const FeatureIcon = styled(Box)({
  marginRight: 10,
});

const modalStyle = {
  position: 'absolute',
  marginTop: 10,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow:'scroll',
  display:'block',
  height:'100%',
  p: 4,
};

const GroupedVenue = () => {
const [getSchedule, setSchedule] = useState([]);
  const booked = {
    '2024-07-03T12:00:00.000Z': true
  }

  const handleChange = newSchedule => {
    setSchedule(newSchedule)
  }

  const renderCustomDateCell = (time, selected, innerRef) => {
      const taken = booked[time.toISOString()]
      return (
      <>
      <div style={{ borderRadius: 3, width: '100%', textAlign: 'center', height: '25px', backgroundColor: taken ? '#ff8873': selected ? '#b3f32c' : '#dddddd' }} ref={innerRef}>
      {taken ? '' : ''}
      </div>
      </>
    )
  }

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const handleOrgNameClick = () => {
    // Navigate to the link when "Org Name" is clicked
    navigate('/org'); // Replace '/your-link' with the actual path
  };

  const handleMoreInfoClick = () => {
    // Open the modal when "More Info" is clicked
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    // Close the modal
    setOpenModal(false);
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} >
          <HeaderImage src={"https://sportivebuddy.com/images/image6.jpeg"} alt="Event Space" />
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'none', lg: 'block', sm: 'block' }}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SideImage src={"https://sportivebuddy.com/images/image5.jpeg"} alt="Side Image 1" />
            </Grid>
            <Grid item xs={6}>
              <SideImage src={"https://sportivebuddy.com/images/image7.jpeg"} alt="Side Image 2" />
            </Grid>
            <Grid item xs={6}>
              <SideImage src={"https://sportivebuddy.com/images/image6.jpeg"} alt="Side Image 3" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Typography variant="h3" gutterBottom>
            Tennis Courts
          </Typography>
          <Grid container alignItems="center" spacing={1} sx={{ display: { xs: 'block', md: 'block', lg: 'none', sm: 'none' }}} >
          <Typography onClick={handleOrgNameClick} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <StarIcon />
            Basaran Sport Club
          </Typography>
          <Typography onClick={handleMoreInfoClick} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <SearchIcon />
            More Info
          </Typography>
          </Grid>
          </Grid>
          <Grid item xs={12}>
          <Grid container spacing={3} alignItems="center" sx={{ display: { xs: 'none', md: 'none', lg: 'block', sm: 'block' }}}>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center">
                <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                  <GroupIcon />
                </Avatar>
                <Box>
                  <Typography variant="subtitle1">MAX PEOPLE</Typography>
                  <Typography variant="h6">15</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box mt={4} sx={{ display: { xs: 'none', md: 'none', lg: 'block', sm: 'block' }}}>
            <Typography variant="h4" gutterBottom>
              Overview
            </Typography>
            <Typography variant="body1" gutterBottom>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in libero non sem fermentum ullamcorper id ut ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi gravida tortor non mi ornare laoreet. Vestibulum dictum accumsan est, id dapibus arcu faucibus eu. Quisque quis semper nulla. Duis vitae malesuada nisi. Phasellus eu nisi ipsum. Vestibulum quis ante non odio dignissim tempus. Mauris quam ante, dapibus ut dignissim in, placerat eget lectus. Mauris malesuada lorem non nisl accumsan, id tempor nibh tempor. Nunc tincidunt non odio nec faucibus.
            </Typography>
            <Typography variant="body1" gutterBottom>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in libero non sem fermentum ullamcorper id ut ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi gravida tortor non mi ornare laoreet. Vestibulum dictum accumsan est, id dapibus arcu faucibus eu. Quisque quis semper nulla. Duis vitae malesuada nisi. Phasellus eu nisi ipsum. Vestibulum quis ante non odio dignissim tempus. Mauris quam ante, dapibus ut dignissim in, placerat eget lectus. Mauris malesuada lorem non nisl accumsan, id tempor nibh tempor. Nunc tincidunt non odio nec faucibus.
            </Typography>
            <Typography variant="body1" gutterBottom>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in libero non sem fermentum ullamcorper id ut ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi gravida tortor non mi ornare laoreet. Vestibulum dictum accumsan est, id dapibus arcu faucibus eu. Quisque quis semper nulla. Duis vitae malesuada nisi. Phasellus eu nisi ipsum. Vestibulum quis ante non odio dignissim tempus. Mauris quam ante, dapibus ut dignissim in, placerat eget lectus. Mauris malesuada lorem non nisl accumsan, id tempor nibh tempor. Nunc tincidunt non odio nec faucibus.
            </Typography>
          </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: { xs: 'none', md: 'none', lg: 'block', sm: 'block' }}}>
            <Typography variant="h5" gutterBottom>
              Features
            </Typography>
            <FeatureBox>
              <FeatureIcon>Icon</FeatureIcon>
              <Typography variant="body1">Free ball.</Typography>
            </FeatureBox>
            <FeatureBox>
              <FeatureIcon>Icon</FeatureIcon>
              <Typography variant="body1">Shower.</Typography>
            </FeatureBox>
            <FeatureBox>
              <FeatureIcon>Icon</FeatureIcon>
              <Typography variant="body1">Nulla consequat massa quis enim.</Typography>
            </FeatureBox>
            <FeatureBox>
              <FeatureIcon>Icon</FeatureIcon>
              <Typography variant="body1">Nulla consequat massa quis enim.</Typography>
            </FeatureBox>
          </Grid>
        </Grid>
        <Grid item xs={4} sx={{ display: { xs: 'block', md: 'block', sm: 'none' }}}>
            <BookingBox>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Court</InputLabel>
                <Select
                  label="Court"
                  defaultValue={10}
                >
                  <MenuItem value={10}>Court 1</MenuItem>
                  <MenuItem value={20}>Court 2</MenuItem>
                  <MenuItem value={30}>Court 3</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="date"
                label="Pick a date"
                type="date"
                defaultValue="2024-07-30"
                sx={{ width: '100%', marginTop: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              
              <Container sx={{ display: { xs: 'block', md: 'block', sm: 'block' }}}>
                  <ScheduleSelector
                  selection={getSchedule}
                  onChange={handleChange}
                  numDays={1}
                  minTime={8}
                  maxTime={22}
                  dateFormat="ddd M/D"
                  renderDateCell={renderCustomDateCell}
                  />
              </Container>
              <Typography variant="h6">
                  Price Box: 10$/h
              </Typography>
              <Button component={Link} to='./book'  variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
                Continue
              </Button>
              <Typography variant="body2" align="center" sx={{ marginTop: 2 }}>
                Cancel for free within 24 hours
              </Typography>
            </BookingBox>
          </Grid>
          <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...modalStyle }}>
        <button onClick={handleCloseModal}>X</button>
          <Typography id="modal-title" variant="h6" component="h2">
            More Information
          </Typography>
          <Grid item xs={8}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={4}>
              <Box display="flex" alignItems="center">
                <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                  <GroupIcon />
                </Avatar>
                <Box>
                  <Typography variant="subtitle1">MAX PEOPLE</Typography>
                  <Typography variant="h6">15</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box mt={4}>
            <Typography variant="h4" gutterBottom>
              Overview
            </Typography>
            <Typography variant="body1" gutterBottom>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in libero non sem fermentum ullamcorper id ut ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi gravida tortor non mi ornare laoreet. Vestibulum dictum accumsan est, id dapibus arcu faucibus eu. Quisque quis semper nulla. Duis vitae malesuada nisi. Phasellus eu nisi ipsum. Vestibulum quis ante non odio dignissim tempus. Mauris quam ante, dapibus ut dignissim in, placerat eget lectus. Mauris malesuada lorem non nisl accumsan, id tempor nibh tempor. Nunc tincidunt non odio nec faucibus.
            </Typography>
            <Typography variant="body1" gutterBottom>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in libero non sem fermentum ullamcorper id ut ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi gravida tortor non mi ornare laoreet. Vestibulum dictum accumsan est, id dapibus arcu faucibus eu. Quisque quis semper nulla. Duis vitae malesuada nisi. Phasellus eu nisi ipsum. Vestibulum quis ante non odio dignissim tempus. Mauris quam ante, dapibus ut dignissim in, placerat eget lectus. Mauris malesuada lorem non nisl accumsan, id tempor nibh tempor. Nunc tincidunt non odio nec faucibus.
            </Typography>
            <Typography variant="body1" gutterBottom>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in libero non sem fermentum ullamcorper id ut ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi gravida tortor non mi ornare laoreet. Vestibulum dictum accumsan est, id dapibus arcu faucibus eu. Quisque quis semper nulla. Duis vitae malesuada nisi. Phasellus eu nisi ipsum. Vestibulum quis ante non odio dignissim tempus. Mauris quam ante, dapibus ut dignissim in, placerat eget lectus. Mauris malesuada lorem non nisl accumsan, id tempor nibh tempor. Nunc tincidunt non odio nec faucibus.
            </Typography>
          </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Features
            </Typography>
            <FeatureBox>
              <FeatureIcon>Icon</FeatureIcon>
              <Typography variant="body1">Free ball.</Typography>
            </FeatureBox>
            <FeatureBox>
              <FeatureIcon>Icon</FeatureIcon>
              <Typography variant="body1">Shower.</Typography>
            </FeatureBox>
            <FeatureBox>
              <FeatureIcon>Icon</FeatureIcon>
              <Typography variant="body1">Nulla consequat massa quis enim.</Typography>
            </FeatureBox>
            <FeatureBox>
              <FeatureIcon>Icon</FeatureIcon>
              <Typography variant="body1">Nulla consequat massa quis enim.</Typography>
            </FeatureBox>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default GroupedVenue;
