import React, {useState} from 'react';
import { Box, Button } from '@mui/material';
import TypeSearch from './TypeSearch';
import { useNavigate } from 'react-router-dom';

function SearchBar({placeHolder}) {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
      <TypeSearch setSearchTerm={setSearchTerm} placeHolder={placeHolder}/>
      <Button variant="contained" color="primary" onClick={handleSearch}>Search</Button>
    </Box>
  );
}

export default SearchBar;
